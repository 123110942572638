
import React, { useState } from 'react';
import { Button } from 'antd';
import { Layout, Menu, Divider } from 'antd';

import './App.css';
import 'antd/dist/antd.css';

import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

import { useAuthState } from 'react-firebase-hooks/auth';
import * as privateFirebase from "./privateFirebase";
const { Header, Content, Footer, Sider } = Layout;
initializeApp(privateFirebase.config);

const auth = getAuth();
// const firestore = firebase.firestore();


export default function App() {
  const [user] = useAuthState(auth);

  const menuSelect = (sel) => {
    console.log(sel.key);
    return;
  }

  return (
    <div className="App">
      <Layout style={{ minHeight: '100vh' }}>
        <Sider >
          <Header className="TopLeft" ><h2>Sheridan Thirsk</h2></Header>
          <Menu theme="dark" defaultSelectedKeys={['4']} mode="inline" onSelect={menuSelect}>
            <Menu.Item key="1">Professional Code</Menu.Item>
            <Menu.Item key="2">Hobby Code</Menu.Item>
            <Menu.Item key="3">
              Education
            </Menu.Item>
            <Menu.Item key="4">
              Socials
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="TopCenter" >
            <h3><code> 
&nbsp; .-.-. &nbsp; .-.-. &nbsp; .-.-. &nbsp; .-.-. &nbsp; .-.-. &nbsp; .-.-<br/>
&nbsp;/ / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \<br/>
`-' &nbsp; `-`-' &nbsp; `-`-' &nbsp; `-`-' &nbsp; `-`-' &nbsp; `-`-'<br/>
              </code>
              </h3></Header>
          <Content className="Main">
            <code><a href="http://twitter.com/threeup">http://twitter.com/threeup</a><br /><br />
              <a href="http://instagram.com/sheridanthree">http://instagram.com/sheridanthree</a><br /><br />
              <a href="https://www.linkedin.com/in/sheridanthirsk/">https://www.linkedin.com/in/sheridanthirsk/</a><br /><br />
            </code>
            {user ? <div>u</div> : <div>x</div>}
            <Button
              type="primary"
              onClick={() => console.log(2)}
            >Click</Button>
          </Content>
          <Footer><Divider>Sheridan Thirsk, 2022</Divider></Footer>

        </Layout>
      </Layout>
    </div>
  );
}